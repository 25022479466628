export enum PASSWORD_STRENGTH_RULES {
  RULE_MIN = 'min',
  RULE_LOW_CASE = 'low',
  RULE_UP_CASE = 'up',
  RULE_SPEC = 'spec',
  RULE_DIGIT = 'digit',
}

export enum PASSWORD_STRENGTH_STATES {
  STATE_PASSED,
  STATE_FAILED,
  STATE_DISABLED,
}
