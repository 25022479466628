import * as Sentry from '@sentry/browser';
import { isProduction } from '@/services/config';
// @ts-ignore
import type { SetupOptions } from './sentry';

export const setupSentry = (options: SetupOptions) => {
  if (isProduction()) {
    Sentry.init(options);
  }
};

export const captureException = (error: any) => {
  let sentryError = error;

  if (isProduction()) {
    if (!sentryError) {
      sentryError = new Error('--- No error message defined ---');
    } else if (typeof sentryError === 'string') {
      sentryError = new Error(sentryError);
    }

    Sentry.captureException(sentryError);
  } else {
    console.error(error);
  }
};
