export enum UI_VARIANT {
  DEFAULT = 'default',
  NEUTRAL = 'neutral',
  WARNING = 'warning',
  ERROR = 'error',
  // Deprecated: new designs no longer have these variants
  ALERT = 'alert',
  SUCCESS = 'success',
}

export enum UI_SIZE {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}
