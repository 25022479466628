import { merge } from 'lodash-es';
import type { BaseConfig } from './config.service.d';

export const config: BaseConfig = {
  // default config values
  companyCountry: 'SK',
  bounds: {
    east: 16.8799829444,
    south: 47.7584288601,
    west: 22.5581376482,
    north: 49.5715740017,
  },
  languages: {},
  currency: 'EUR',
  validationApiUrl: 'https://api-cart.websupport.sk/',
};

export function setConfigValues(newValues: Partial<BaseConfig>) {
  merge(config, newValues);
}

export default config;
