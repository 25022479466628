import {defineComponent, h} from 'vue';
import type {App, Component} from '@vue/runtime-core';


export const setupWsWrapper = ((app: App) => {
  app.component('WsWrapper', WsWrapper);
});


export const WsWrapper: Component = defineComponent({
  props: {
    wrap: { type: Boolean, default: true },
    element: { type: String, default: 'div' },
  },
  render() {
    // Render only contents if wrap is false
    if (!this.wrap) {return h(this.$slots.default);}

    // Wrap the contents in a DIV (or props.element type tag) and render the contents
    return h(this.element, h(this.$slots.default));
  },
});
