<template>
  <main class="main" :class="'theme--' + currentTheme">
    <section class="page-layout">
      <ws-login-card>
        <transition name="fade" mode="out-in">
          <div>
            <template v-if="currentPage === 'email-form'">
              <email-form
                key="email-form"
                @forgot-login-info="currentPage = 'redirect'"
              />
            </template>
            <template v-if="currentPage === 'redirect'">
              <redirect-to-webadmin
                key="redirect-to-webadmin"
                ref="redirectComponent"
                @back-to-webadmin="currentPage = 'email-form'"
              />
            </template>
          </div>
        </transition>
      </ws-login-card>
      <help-section />
    </section>
  </main>
</template>

<script setup lang="ts">
import HelpSection from '@/components/login/HelpSection.vue';
import WsLoginCard from '@/components/login/WsLoginCard.vue';
import EmailForm from '@/components/login/EmailForm.vue';
import RedirectToWebadmin from '@/components/login/RedirectToWebadmin.vue';
import {currentTheme} from '@/services/theming';

import {ref} from 'vue';

const currentPage = ref('email-form');
</script>

<style lang="scss" scoped>
@import "@loopia-group/assets/dist/themes/cary/cary_vars";

.main {
  background: $ws-webmail-bg;
}

.page-layout {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
@media screen and (max-width: $ws-breakpoint-mobile-max) {
  .page-layout {
    flex-wrap: wrap;
  }
}
</style>
