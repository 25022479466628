<template>
  <q-btn-dropdown
    :dropdown-icon="`img:${iconArrowDown}`"
    data-test="select-country"
    class="q-mt-xs"
  >
    <template v-slot:label>
      <div class="row items-center no-wrap">
        <ws-country-flag
          :key="key"
          :country="currentFlag"
          class="q-my-none"
          size="small"
        />
        <div class="ws-color-navy text-center text-capitalize q-px-sm">
          {{ getLanguageName(localeToFlagCode(currentFlag)) }}
        </div>
      </div>
    </template>

    <q-list class="footer-dropdown cy-language-selector">
      <q-item
        v-for="(country, index) in countries"
        :key="index"
        :title="country"
        clickable
        :data-test="`set-language-${country}`"
        @click="setLanguage(country)"
      >
        <q-item-section side>
          <ws-country-flag size="small" :country="localeToFlagCode(country)" />
        </q-item-section>
        <q-item-section>
          <q-item-label>
            {{ getLanguageName(localeToFlagCode(country)) }}
          </q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onBeforeMount, ref, watch } from 'vue';
import iconArrowDown from '@loopia-group/assets/dist/icons/arrow_down.svg';
import WsCountryFlag from './WsCountryFlag.vue';
import { getLanguageName, localeToFlagCode, setLanguage } from '@/i18n';
import { getConfig } from '@/services/config';
import { getAllowedLanguages } from '@/services/countries';

const config = getConfig();
const $i18n = useI18n();
let countries: string[];
const currentFlag = ref('');
const key = ref(0);

watch($i18n.locale, () => {
  currentFlag.value = localeToFlagCode($i18n.locale.value);
  key.value++;
});

onBeforeMount(() => {
  currentFlag.value = localeToFlagCode($i18n.locale.value);
});

function filterLanguages() {
  const allowedCountries = getAllowedLanguages(config.country);

  countries = $i18n.availableLocales.filter((e) => allowedCountries.includes(e));
}

filterLanguages();
</script>

<style lang="scss" scoped>
// cheating default quasar styles
.q-btn--actionable.q-btn--standard:active:before,
.q-btn--actionable.q-btn--standard.q-btn--active:before,
.q-btn:before {
  box-shadow: none;
  border: none;
}

.ws-color-navy {
  color: $ws-cary-navy;
}
</style>
