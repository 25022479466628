<template>
  <section class="ws-typography section-fit">
    <q-card class="login-card q-my-sm">
      <img v-bind="getLogo" :alt="t('company_logo')" />

      <slot></slot>

      <div
        class="footer"
        :class="{
          'items-center': isTheme(Theme.LOOPIA) || isTheme(Theme.ACTIVE24),
          'items-end': isTheme(Theme.CARY),
        }"
      >
        <div class="help-text">
          <h5 class="q-mb-sm q-mt-xl">
            {{ t('help-question') }}
          </h5>
          <!-- eslint-disable vue/no-v-html -->
          <p
            class="customized-paragraph text-ws-base110 small q-mb-none"
            v-html="xss(t('help-hint',{'kb_url': getUrl('help'), 'support_url': getUrl('contact')}))"
          ></p>
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <img v-bind="getFooterImg" class="q-ml-lg" alt="" />
      </div>
    </q-card>
    <div class="row no-wrap justify-between items-start">
      <ws-select-country class="cy-country-btn" aria-controls="select-country" />
      <div class="flex justify-center max-w-custom">
        <a
          v-for="(link, index) in links"
          :key="index"
          class="anchor-text1 q-mr-lg q-mt-md"
          :href="getUrl(link)"
        > {{ t(link) }} </a>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import WsSelectCountry from '@/tmp_uikit/component-3/WsSelectCountry.vue';
import {currentTheme, Theme, isTheme} from '@/services/theming';
import getUrl from '@/services/urls';
import {computed} from 'vue';
import xss from './../../helpers/xss';

const $i18n = useI18n();
const {t} = $i18n;
const links = [
  'help',
  'terms',
  'privacy',
];

const getLogo = computed(() => {
  switch (currentTheme.value) {
  case Theme.LOOPIA:
    return {
      'src': 'https://cdn.websupport.eu/companies/loopia/logo-small.svg',
      'alt': 'Loopia',
      'height': 31,
    };
  case Theme.ACTIVE24:
    return {
      'src': 'https://cdn.active24.eu/logos/a24-logo-green.svg',
      'alt': 'Active24',
      'height': 31,
    };
  case Theme.CARY:
  default:
    return {
      'src': 'https://cdn.websupport.eu/companies/ws/logo.svg',
      'alt': 'Websupport',
      'height': 40,
    };
  }
});

const getFooterImg = computed(() => {
  switch (currentTheme.value) {
  case Theme.LOOPIA:
    return {
      'srcset': '/images/login-loopia@2x.png 2x',
      'src': '/images/login-loopia.png',
      'width': 141,
      'height': 141,
    };
  case Theme.ACTIVE24:
    return {
      'srcset': '/images/footer-active24@2x.png 2x',
      'src': '/images/footer-active24.png',
      'width': 140,
      'height': 120,
    };
  case Theme.CARY:
  default:
    return {
      'srcset': '/images/mascot-headphones-retina.png 2x',
      'src': '/images/mascot-headphones.png',
      'width': 143,
      'height': 121,
    };
  }
});
</script>

<style lang="scss" scoped>
.section-fit {
  width: 468px;
  max-width: 468px;
}

section {
  margin: map-get($space-sm, y);

  // fixed quasar auto alignment to center
  .q-card > img {
    width: auto;
    margin-bottom: map-get($space-4xl, y);
  }

  .login-card {
    max-width: 468px;
    min-width: 420px;
    box-shadow: 0 16px 32px rgba(190, 200, 209, 0.25);
    border-radius: 6px;
    padding: map-get($space-3xl, y) map-get($space-3xl-b, x) map-get($space-3xl, y) map-get($space-3xl, x);
  }

  .footer {
    display: none;
  }
}

.theme--cary {
  .help-text:deep(>p>a) {
    color: $theme-primary;
  }
}

.theme--loopia {
  .help-text:deep(>p>a) {
    color: $loopia-red-main2;
  }
}

.theme--a24 {
  .help-text:deep(>p>a) {
    color: $a24-primary-darker-40;
  }
}

// this is fix for bad item alignment in Slovak translate
div.max-w-custom {
  max-width: 280px;
}

@media screen and (min-width: $ws-breakpoint-desktop-min) {
  section {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: $ws-breakpoint-tablet-min) and (max-width: $ws-breakpoint-tablet-max) {
  section {
    margin-left: 32px;
  }
}

@media screen and (max-width: $ws-breakpoint-tablet-max) {
  section {
    .login-card {
      max-width: 430px;
    }
  }

  .section-fit {
      max-width: 430px;
      min-width: auto;
  }
}

@media screen and (max-width: $ws-breakpoint-tablet-sm) {
  section {
    .login-card {
      min-width: 410px;
    }
  }
}

@media screen and (max-width: $ws-breakpoint-mobile-max) {
  section {
    .login-card {
      padding-bottom: 0;
    }

    .footer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        border-top: 1px solid $ws-navy-60;
        margin-top: 32px;

      .help-text {
        h5 {
          font-size: 18px;
        }

        .customized-paragraph {
            margin-bottom: 40px;
            line-height: 21px;
        }
      }
    }
  }

  .theme--cary {
    .help-text {
      margin-right: -50px;
    }
  }
}

@media screen and (max-width: 414px) {
  section {
    .login-card {
      min-width: auto;
      padding: map-get($space-3xl, y) map-get($space-lg, x) 0 map-get($space-lg, x);
    }
  }
}
</style>
