<template>
  <div class="ws-typography horizontal-divider col-12">
    <div class="horizontal-line"></div>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
@import '@loopia-group/assets/dist/_wsk_vars';

.horizontal-divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 25px;

  .horizontal-line {
    border: .5px solid $ws-cary-navy-4;
    width: 100%;
    height: 0;
  }
}
</style>
