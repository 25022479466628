import { ref } from 'vue';
import type { Ref } from 'vue';

export enum Theme {
  ADMIN = 'admin',
  ACTIVE24 = 'a24',
  CARY = 'cary',
  LOOPIA = 'loopia',
}

export const currentTheme: Ref<Theme> = ref(Theme.CARY);

export function isTheme(theme: Theme): boolean {
  return currentTheme.value === theme;
}

export function setTheme(theme: Theme): void {
  currentTheme.value = theme;
}

declare global {
  interface Window {
    setTheme?: (theme: Theme) => void
    isTheme?: (theme: Theme) => boolean
  }
}

window.setTheme = setTheme;
window.isTheme = isTheme;
