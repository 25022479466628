<template>
  <q-input
    ref="qfield"
    class="ws-input ws-field"
    :class="{
      'ws-input-huge': huge,
      responsive,
      'ws-dark': dark,
      'ws-has-value': modelValue,
      'messages-overflow': messagesOverflow,
    }"
    v-bind="$attrs"
    :model-value="modelValue"
    :standout="
      themeAdmin
        ? undefined
        : dark
          ? 'bg-dark text-white'
          : 'bg-white ws-text-darker'
    "
    :square="themeAdmin"
    :outlined="themeCary"
    bottom-slots
    :error="props.error"
    no-error-icon
    :lazy-rules="lazyRules"
    :required="required"
  >
    <!-- credit: https://stackoverflow.com/a/50892881/861615 -->
    <template v-for="(_, slot) of $slots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope || {}"></slot>
    </template>
    <!--    <template v-slot:append="scope">-->
    <!--      <ws-optional v-if="optionalText && !required" />-->
    <!--      &lt;!&ndash; debug helpers &ndash;&gt;-->
    <!--      &lt;!&ndash; <pre>r{{ !!required ? 1 : 0 }}</pre> &ndash;&gt;-->
    <!--      &lt;!&ndash; <pre>o{{ !!optionalText ? 1 : 0 }}</pre> &ndash;&gt;-->
    <!--      &lt;!&ndash; <pre>l{{ !!lazyRules ? 1 : 0 }}</pre> &ndash;&gt;-->
    <!--      &lt;!&ndash; <pre>m{{ !!get(messages, 'length') }}</pre> &ndash;&gt;-->
    <!--      <slot name="append" v-bind="scope" />-->
    <!--    </template>-->
  </q-input>
</template>

<script setup lang="ts">
import { QInput } from 'quasar';
// import WsFieldMixin  from './WsFieldMixin.vue';

const props = defineProps({
  value: { type: String || Number, default: '' },
  modelValue: { type: String || Number, default: '' },
  dark: { type: Boolean },
  huge: { type: Boolean },
  responsive: { type: Boolean },
  optionalText: { type: Boolean },
  error: { type: Boolean },
  errorMessage: { type: String, default: '' },
  messagesOverflow: { type: Boolean, default: false },
});

const themeAdmin = true;
const themeCary = false;
const lazyRules = false;
const required = true;
</script>

<style lang="scss" scoped>
.ws-input.messages-overflow::v-deep(.q-field__bottom) {
  height: 0;
  overflow-y: visible;
}
.q-textarea {
  border: var(--ws-medium-line, 1px) solid
    var(--ws-lines-color, var(--ws-base050, #e2e5e9));
  &::v-deep(textarea) {
      resize: none;
  }
}
.ws-input.ws-field.q-textarea > .q-field__inner > .q-field__control:after {
  border: none;
}

// These are some input style fixes based on conditions from figma
.ws-input.ws-field.q-input
:deep(.q-field__inner > .q-field__bottom > .q-field__messages > :last-child) {
  margin-bottom: map-get($space-xs, y);
  margin-left: map-get($space-xs, x);
  font-size: var(--ws-typo-xs);
}

  .ws-input.ws-field.q-input.q-field--error :deep(.q-field__inner > .q-field__control:after) {
    border-color: var(--ws-error-validation);
  }

  .ws-input.ws-field.q-input.q-field--error :deep(.q-field__inner
  > .q-field__control > .q-field__control-container > .q-field__label) {
    color: var(--ws-error-validation);
  }

  :deep(.q-field__messages) {
    color: var(--ws-error-validation)
  }
  .q-field {
    @media screen and (max-width: $ws-breakpoint-mobile-max) {
      font-size: $ws-font-size-base;
    }
  }
</style>
