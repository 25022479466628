<!-- CREDIT: https://github.com/P3trur0/vue-country-flag/blob/master/packages/vue-country-flag/src/CountryFlag.vue -->
<!-- CREDIT (flags source): https://github.com/LeCoupa/vueflags -->
<template>
  <!-- eslint-disable vue/no-v-html -->
  <span v-if="country" class="ws-country-flag" :class="size" v-html="flagImage"></span>
  <!-- eslint-enable vue/no-v-html -->
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { UI_SIZE } from '@/tmp_uikit/component-3/WsStateBanner.enum';

const props = defineProps({
  country: { type: String, default: '' },
  size: { type: String, default: UI_SIZE.MEDIUM },
});

const flags = import.meta.glob('../../assets/icons/flags/*.svg', { as: 'raw' });
const flagImage = ref('');

async function getImage() {
  try {
    const iconUrl = flags[`../../assets/icons/flags/${props.country.toUpperCase()}.svg`] ?? null;

    if (!iconUrl) {
      console.error(`Country flag not found (${props.country.toUpperCase()}).`);
      return;
    }

    flagImage.value = await iconUrl();
  } catch (err) {
    console.error(err);
  }
}

getImage();
</script>

<style lang="scss" scoped>
.ws-country-flag {
  line-height: 1px; // not to have line paddings
  vertical-align: middle;
  width: 22.4px;
  height: 16px;
  display: inline-block;

  &.small {
    width: 16px;
    height: 11.4px;
  }

  &.large {
    width: 32px;
    height: 22.8px;
  }

  > img {
    height: 100%;
    width: 100%;
  }
}
</style>
