<template>
  <ws-wrapper
    :class="{
      'ws-icon ws-icon__wrapper flex items-center': wrap,
      circle: circle,
      [bgColorClass()]: bgColor,
    }"
    :wrap="wrap"
    :style="sizes"
    v-bind="$attrs"
  >
    <inline-svg
      v-if="!unloaded()"
      v-bind="!wrap ? $attrs : null"
      :class="classes()"
      :src="iconUrl"
      :title="name"
      :style="sizes()"
      :circle="circle"
    />

    <span v-else v-bind="$attrs" :class="classes" :title="name" :style="sizes">
      □
    </span>

    <slot></slot>
  </ws-wrapper>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import InlineSvg from 'vue-inline-svg';

const iconsRootFolder = '../../../node_modules/@loopia-group/assets/dist/icons';
const icons = import.meta.glob(
  '../../../node_modules/@loopia-group/assets/dist/icons/*.svg',
  {eager: true}
);

const props = defineProps({
  name: { type: String, default: '' },
  wrap: { type: Boolean },
  color: { type: String, required: false, default: '' },
  bgColor: { type: String, default: '' },
  circle: { type: Boolean },
  size: { type: Number, required: false, default: null },
  height: { type: String, default: '16' },
  width: { type: String, default: '16' },
});

const loading = ref(true);
const iconUrl = ref('');

function unloaded() {
  return loading.value;
}

function classes() {
  const classesArray = ['ws-icon'];

  if (props.color) {
    classesArray.push(`text-${props.color}`);
  }

  if (unloaded()) {
    classesArray.push('unloaded');
  }

  return classesArray;
}

function bgColorClass() {
  return `bg-${props.bgColor}`;
}

function sizes() {
  let height;
  let width;

  if (props.size) {
    height = props.size.toString();
    width = props.size.toString();
  } else {
    height = props.height;
    width = props.width;
  }

  return `
      height: ${height}px;
      min-height: ${height}px;
      width: ${width}px;
      min-width: ${width}px;
    `;
}

const config = {
  theme: 'cary',
};

async function getIcon() {
  const themeUrl = `${iconsRootFolder}/${config.theme}/${props.name}.svg`;
  const fallbackUrl = `${iconsRootFolder}/${props.name}.svg`;
  const iconImports = icons[themeUrl] || icons[fallbackUrl];

  if (iconImports === undefined) {
    console.error(`Icon not found: ${props.name}`);
  } else {
    // @ts-ignore
    iconUrl.value = iconImports.default;
    loading.value = false;
  }
}

getIcon();
watch(() => props.name, getIcon);
</script>

<style lang="scss">
.ws-icon {
  *:not([fill='none']) {
    fill: currentColor;
  }

  &.circle {
    border-radius: 50%;
  }
}

.circle {
  background-color: currentColor;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    zoom: 0.75;
  }
}
</style>
