<template>
  <div ref="banner" class="ws-state-banner" :class="`${size} ${type}`">
    <template v-if="props.title || props.text">
      <ws-icon v-if="props.icon" :name="getIcon()" :size="22" wrap />

      <div class="ws-state-banner__content">
        <div v-if="props.title" class="ws-state-banner__title">
          {{ props.title }}
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-if="props.text" class="ws-state-banner__text" v-html="xss(props.text)">
        </p>
      </div>
    </template>

    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import WsIcon from '@/tmp_uikit/component-3/WsIcon.vue';
import { UI_SIZE, UI_VARIANT } from './WsStateBanner.enum';
import xss from 'xss';

const props = defineProps({
  size: { type: String, default: UI_SIZE.LARGE },
  type: { type: String, default: UI_VARIANT.DEFAULT },

  title: { type: String, default: '' },
  icon: { type: Boolean },
  text: { type: String, default: '' },
});

const getIcon = (): string => {
  switch (props.type) {
  case UI_VARIANT.NEUTRAL:
    return 'success_full';
  case UI_VARIANT.WARNING:
    return 'alert_triangle_full';
  case UI_VARIANT.ERROR:
    return 'alert_circle_full';
  case UI_VARIANT.DEFAULT:
  default:
    return 'info_full';
  }
};
</script>

<style lang="scss" scoped>
.ws-state-banner {
  display: flex;
  padding: map-get($space-md, y);
  border: $ws-border;
  border-radius: 6px;
  color: $ws-cary-navy;

  &__content {
    flex: 1;
  }

  &__title {
    font-weight: map-get($text-weights, bold);
    font-size: 16px;
  }

  &__text {
    margin: 0;
    font-size: 14.1px;
    line-height: 19px;
  }

  .ws-icon {
    align-self: flex-start;
    margin-top: map-get($space-xs, y);
    margin-right: map-get($space-md, x);
  }

  // Sizes
  &.large {
    padding: map-get($space-md, y) map-get($space-md, x);
  }

  &.medium {
    padding: map-get($space-sm, y) map-get($space-md, x);
  }

  &.small {
    padding: map-get($space-xs, y) map-get($space-sm, x);
  }

  // Types

  &.default {
    background: #E7F0F8;
    border-color: rgba(20, 55, 255, 0.2);

    .ws-icon, .ws-state-banner__title {
      color: $ws-quaternary-neon;
    }
  }

  &.error {
    background: #FFDCDC;
    border-color: rgba(255, 0, 90, 0.2);
    color: var(--ws-error-validation);
  }

  &.neutral, &.gray {
    background: rgba(44, 56, 67, 0.02);
    border-color: $ws-cary-navy-4;

    .ws-icon {
      color: #2FD17D;
    }
  }

  &.warning {
    background: #FFF5D2;
    border-color: rgba(255, 90, 20, 0.2);

    .ws-icon, .ws-state-banner__title {
      color: #FF5A14;
    }
  }

  // Deprecated
  &.success {
    background: $ws-light-bg-success;
  }

  &.alert {
    background: $ws-light-bg-alert;
  }
}
</style>
