import { redirect } from '@loopia-group/utils';
import { useCookies } from 'vue3-cookies';

const { cookies } = useCookies();

export const SAVED_REDIRECT_KEY = 'savedRedirect';

export function setRedirect(url: string): void {
  cookies.set(SAVED_REDIRECT_KEY, url, 0);
}

export function checkRedirect(): boolean {
  const hash = window.location.hash?.slice(1);
  const lastNavigation = (performance.getEntriesByType('navigation').shift() as PerformanceNavigationTiming);

  // User sessions are cleared out when accessing URL with #logout at the end.
  // e.g.:  https://webmail.websupport.se/#logout
  // This URL is set as an "after logout" redirect in RoundCube.
  if (hash === 'logout') {
    cookies.remove(SAVED_REDIRECT_KEY);
  } else if (lastNavigation && lastNavigation.type === 'back_forward') {
    // If the login fails for any reason and the user hits the Back button, he won't get
    // redirected straight back again. We'll regard him as logged out instead.
    cookies.remove(SAVED_REDIRECT_KEY);
  } else {
    const savedRedirect = cookies.get(SAVED_REDIRECT_KEY);

    if (savedRedirect) {
      redirect(savedRedirect);
      return true;
    }
  }

  return false;
}
