let crowdinInitialized = false;
export const IN_CONTEXT_LOCALIZATION_LANG_CODE = 'xx-XX';
export const IN_CONTEXT_LOCALIZATION_SCRIPT_CONTENT = 'var _jipt = []; ' +
  '_jipt.push([\'project\', \'unified-login-page\']);';
export const IN_CONTEXT_LOCALIZATION_SCRIPT_SRC = '//cdn.crowdin.com/jipt/jipt.js';

export function initCrowdinInContextLocalization(locale: string) {
  if (!crowdinInitialized && locale === IN_CONTEXT_LOCALIZATION_LANG_CODE) {
    let script;
    // https://crowdin.com/project/unified-login-page/tools/in-context
    script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = IN_CONTEXT_LOCALIZATION_SCRIPT_CONTENT;
    document.head.appendChild(script);

    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = IN_CONTEXT_LOCALIZATION_SCRIPT_SRC;
    document.head.appendChild(script);

    crowdinInitialized = true;
  }
}
