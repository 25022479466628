import { PASSWORD_STRENGTH_RULES, PASSWORD_STRENGTH_STATES } from './PasswordStrengthValidator.enum';
import type { Options } from './PasswordStrengthValidator.d';
import type { Result } from './PasswordStrengthValidator.d';

export class PasswordStrengthValidator {
  private rules: PASSWORD_STRENGTH_RULES[];

  private rulesResults: Result[] = [];

  private options: Options;

  constructor(options?: Options) {
    // @ts-ignore
    this.rules = Object.values(RULES);

    const defaults: Options = {
      message: '',
      validatorData: {
        [PASSWORD_STRENGTH_RULES.RULE_MIN]: 8,
        [PASSWORD_STRENGTH_RULES.RULE_LOW_CASE]: 1,
        [PASSWORD_STRENGTH_RULES.RULE_UP_CASE]: 1,
        [PASSWORD_STRENGTH_RULES.RULE_SPEC]: 1,
        [PASSWORD_STRENGTH_RULES.RULE_DIGIT]: 1,
      },
    };
    this.options = { ...defaults, ...options };
  }

  validate(value: string): Result[] {
    this.rulesResults = [];

    if (typeof value !== 'string') {
      return []; // other checks will throw errors or exception, so end validation here.
    }

    this.rules.forEach((rule) => {
      this.validateRule(rule, value);
    });

    return this.rulesResults;
  }

  validateRule(rule: PASSWORD_STRENGTH_RULES, value: string): void {
    if (this.options.validatorData[rule] === 0) {
      // skip rule which have zero expected occurrence
      return;
    }

    if (rule === PASSWORD_STRENGTH_RULES.RULE_MIN) {
      this.validateMinRule(value);
      return;
    }

    let found;
    switch (rule) {
    case PASSWORD_STRENGTH_RULES.RULE_LOW_CASE:
      found = value.match(/[a-z]/g);
      break;
    case PASSWORD_STRENGTH_RULES.RULE_UP_CASE:
      found = value.match(/[A-Z]/g);
      break;
    case PASSWORD_STRENGTH_RULES.RULE_DIGIT:
      found = value.match(/[\d]/g);
      break;
    case PASSWORD_STRENGTH_RULES.RULE_SPEC:
      found = value.match(/[\W]/g);
      break;
    default:
      found = null;
      break;
    }

    if (!found || found.length < this.options.validatorData[rule]) {
      this.addFailRule(rule, {
        found: found ? found.length : 0,
        required: this.options.validatorData[rule],
      });
    } else {
      this.addPassRule(rule);
    }
  }

  validateMinRule(value: string) {
    const rule = PASSWORD_STRENGTH_RULES.RULE_MIN;
    if (value.length < this.options.validatorData[rule]) {
      this.addFailRule(rule, {
        found: value.length,
        required: this.options.validatorData[rule]
      });
    } else {
      this.addPassRule(rule);
    }
  }

  addFailRule(rule: PASSWORD_STRENGTH_RULES, values: { found: number; required: number }): void {
    this.rulesResults.push({
      result: PASSWORD_STRENGTH_STATES.STATE_FAILED,
      rule,
      found: values.found,
      required: values.required,
    });
  }

  addPassRule(rule: PASSWORD_STRENGTH_RULES): void {
    this.rulesResults.push({
      result: PASSWORD_STRENGTH_STATES.STATE_PASSED,
      rule,
    });
  }

  getRulesResults(): Result[] {
    return this.rulesResults;
  }
}

export default PasswordStrengthValidator;
