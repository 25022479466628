<template>
  <section class="ws-typography row">
    <picture>
      <source
        type="image/webp"
        :srcset="getLoginImage.webpSrcset"
        :sizes="getLoginImage.width + 'px'"
      />
      <source :srcset="getLoginImage.srcset" :sizes="getLoginImage.width + 'px'" />
      <img
        :src="getLoginImage.src"
        :width="getLoginImage.width"
        :height="getLoginImage.height"
        class="login-image"
        alt=""
      />
    </picture>
    <div class="help-text">
      <horizontal-divider />
      <h5 class="q-mb-sm q-mt-xl">
        {{ t('help-question') }}
      </h5>
      <!-- eslint-disable vue/no-v-html -->
      <p
        class="customized-paragraph text-ws-base110 small q-mb-none"
        v-html="xss(t('help-hint',{'kb_url': getUrl('help'), 'support_url': getUrl('contact')}))"
      ></p>
      <!--eslint-enable vue/no-v-html -->
    </div>
  </section>
</template>

<script setup lang="ts">
import {useI18n} from 'vue-i18n';
import xss from 'xss';
import getUrl from '@/services/urls';
import {computed} from 'vue';
import {currentTheme, Theme} from '@/services/theming';
import HorizontalDivider from '@/components/login/HorizontalDivider.vue';

const $i18n = useI18n();
const {t} = $i18n;

const getLoginImage = computed(() => {
  switch (currentTheme.value) {
  case Theme.LOOPIA:
    return {
      width: 460,
      height: 460,
      srcset: '/images/login-loopia@2x.png 2x',
      src: '/images/login-loopia.png',
      webpSrcset: '/images/login-loopia@2x.webp 2x',
      webpSrc: '/images/login-loopia.webp',
    };
  case Theme.ACTIVE24:
    return {
      width: 550,
      height: 418,
      srcset: '/images/login-active24@2x.png 2x',
      src: '/images/login-active24.png',
      webpSrcset: '/images/login-active24@2x.webp 2x',
      webpSrc: '/images/login-active24.webp',
    };
  case Theme.CARY:
  default:
    return {
      width: 607,
      height: 460,
      srcset: '/images/login-cary@2x.png 2x',
      src: '/images/login-cary.png',
      webpSrcset: '/images/login-cary@2x.webp 2x',
      webpSrc: '/images/login-cary.webp',
    };
  }
});
</script>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 660px;
  width: auto;
  margin: 35px 8px 0 72px;
  padding-left: 69px;

  .icon-pos-fix {
    border-radius: $ws-border-radius;
  }

  .help-text {
    margin-left: 79px;
    max-width: 364px;
  }

  .login-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}
.theme--cary {
  .help-text:deep(>p>a) {
    color: $theme-primary;
  }
}

.theme--a24 {
  .help-text:deep(>p>a) {
    color: $a24-primary-darker-40;
  }
}

.theme--loopia {
  .help-text:deep(>p>a) {
    color: $loopia-red-main2;
  }

  .help-text {
   margin-left: map-get($space-3xl, y);
  }
}

@media screen and (min-width: $ws-breakpoint-tablet-min) and (max-width: $ws-breakpoint-tablet-max) {
  section {
    margin: 0 32px 0 0;
  }
}

@media screen and (max-width: $ws-breakpoint-tablet-lg) {
  section {
    .help-text {
      width: 60%;
    }
  }

  .theme--loopia {
    .help-text {
      width: 80%;
    }
  }
}

@media screen and (max-width: $ws-breakpoint-tablet-md) {
  section {
    max-width: 450px;
    padding-left: map-get($space-2xl, x);
    .help-text {
      margin-left: 55px;
    }
  }
}

@media screen and (max-width: $ws-breakpoint-tablet-sm) {
  section {
    .help-text {
      margin-left: 40px;
    }
  }

  .theme--loopia {
    .help-text {
      width: 75%;
    }
  }
}

@media screen and (max-width: $ws-breakpoint-mobile-max) {
  section {
    display: none;
    .help-text {
      max-width: 230px;
      align-self: start;
    }
  }
}
</style>
